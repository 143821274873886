import React from "react";
import { Button, Card } from "react-bootstrap";
import styled from "styled-components";
import { ProductInfo } from "../../../types";
import Skeleton from "react-loading-skeleton";
import { DeleteIcon, EditIcon } from "../../../Assets/icons";
import { soldActive } from "./MenuProductsSection";

import {useTranslation} from 'react-i18next'
const StyledMenuItemCard = styled(Card)`
  border-radius: 0 24px 0 24px;
  transition: all 0.5s ease;
  box-shadow: 0 4px 16px #b1b1b152;
  border: 0;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`;

const StyledCatImgSkeleton = styled(Skeleton)`
  display: flex;
  object-fit: cover;
  border-radius: 0 24px 0 24px;
`;

const StyledCardImg = styled(Card.Img)`
  object-fit: cover;
  border-radius: 0 24px 0 24px;
`;

const StyledCardEdgeButtonContainer = styled.div<{ top?: number }>`
  position: absolute;
  bottom: ${(props) => (props.top ? "initial" : 0)};
  top: ${(props) => (props.top ? 0 : "initial")};
  right: 0;
`;

const StyledCardEdgeButton = styled(Button)<{ top?: number }>`
  border-radius: ${({ top }) => (top ? "0 24px 0 24px" : "24px 0 0 0")};
  font-size: 1.6rem;
  padding: 0 0.7rem 5px 0.7rem;
`;

const StyledCardTitle = styled(Card.Title)`
  cursor: pointer;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const SoldContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  -webkit-transition: width 1s ease, height 1s ease;
  -moz-transition: width 1s ease, height 1s ease;
  -o-transition: width 1s ease, height 1s ease;
  transition: width 0.4s ease, height 0.4s ease;
`;

const SoldBadge = styled.span`
  // Top Left
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-30%) translateY(0%) rotate(-45deg);
  -webkit-transform: translateX(-30%) translateY(0%) rotate(-45deg);
  transform: translateX(-30%) translateY(0%) rotate(-45deg);
  -ms-transform-origin: top right;
  -webkit-transform-origin: top right;
  transform-origin: top right;

  // Badge
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 10px;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    margin: 0 -5px;
    width: 100%;
    height: 100%;
    background: inherit;
    min-width: 55px;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  &.red {
    background: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
  }
`;

export function SoldBanner(props: { reduction: number }) {
  const { t } = useTranslation();
  if (props.reduction > 0) {
    return (
      <SoldContainer>
        <SoldBadge className="top-left red">
          {props.reduction} % {t("de réduction")}
        </SoldBadge>
      </SoldContainer>
    );
  }
  return null;
}

export function FoodItemCardSkeleton(props) {
  return <FoodItemCard {...props} skeleton={true} />;
}

export function FoodItemCard(
  props: {
    item?: ProductInfo;
    isAdded?: boolean;
    skeleton?: boolean;
    soldActive: boolean;
    onClick: () => void;
    onAdd?: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
    onLoad?: () => void;
    children?: React.ReactNode;
  } = {
    isAdded: false,
    soldActive: false,
    skeleton: false,
    onClick: () => {},
    onLoad: () => {},
  }
) {
  const { t } = useTranslation();
  const isSkeleton = props.skeleton || !props.item;

  if (isSkeleton) {
    return (
      <StyledMenuItemCard>
        <StyledCatImgSkeleton height={200} />
        <Card.Body>
          <StyledCardTitle>
            <Skeleton />
          </StyledCardTitle>

          <Card.Subtitle className="text-info py-1">
            <Skeleton />
          </Card.Subtitle>

          <Card.Text
            className="py-2"
            style={{
              fontSize: 16,
              height: 70,
              overflow: "hidden",
              textOverflow: "hidden",
            }}
          >
            <Skeleton count={2} />
          </Card.Text>

          <Card.Subtitle className="text-primary"></Card.Subtitle>
        </Card.Body>
      </StyledMenuItemCard>
    );
  }

  const originalPrice = props.item.price;
  const currentPrice =
    soldActive && props.item.sold
      ? props.item.price * (1 - props.item.sold / 100)
      : props.item.price;

  return (
    <StyledMenuItemCard className="my-3 text-start">
      <StyledCardImg
        height={200}
        width="18rem"
        onLoad={() => {
          props.onLoad && props.onLoad();
        }}
        onClick={props.onClick}
        loading="lazy"
        variant="top"
        src={props.item?.img}
        alt={props.item?.name}
      />
      <SoldBanner reduction={props.item.sold} />
      <Card.Body onClick={props.onClick}>
        <StyledCardTitle>{t(props.item.name)}</StyledCardTitle>
        <Card.Subtitle className="text-info py-1">
           {t(props.item.size)}
        </Card.Subtitle>
        <Card.Text
          style={{
            fontSize: 16,
            height: 70,
            overflow: "hidden",
            textOverflow: "hidden",
          }}
        >
        {t(props.item.description)}
        </Card.Text>

        <Card.Subtitle className="text-primary">
          {currentPrice } {t("DA")}

          <sup className="text-danger ms-1">
            <s>
              {props.soldActive && props.item.sold ? originalPrice + " DA" : ""}
            </s>
          </sup>
        </Card.Subtitle>
      </Card.Body>
      {props.onAdd ? (
        <StyledCardEdgeButtonContainer>
          <StyledCardEdgeButton
            onClick={props.onAdd}
            className={props.isAdded ? "text-white " : ""}
            variant={props.isAdded ? "warning" : "primary"}
          >
            +
          </StyledCardEdgeButton>
        </StyledCardEdgeButtonContainer>
      ) : (
        <></>
      )}

      {props.onDelete ? (
        <StyledCardEdgeButtonContainer>
          <StyledCardEdgeButton onClick={props.onDelete} variant="danger">
            <DeleteIcon fill={"white"} height={16} />
          </StyledCardEdgeButton>
        </StyledCardEdgeButtonContainer>
      ) : (
        <></>
      )}
      {props.onEdit ? (
        <StyledCardEdgeButtonContainer top={1}>
          <StyledCardEdgeButton top={1} onClick={props.onEdit} variant="info">
            <EditIcon fill={"white"} height={16} />
          </StyledCardEdgeButton>
        </StyledCardEdgeButtonContainer>
      ) : (
        <></>
      )}
    </StyledMenuItemCard>
  );
}
