import styled from "styled-components";
import useUserOrderDetailsPage from "../../../hooks/useUserOrderDetailsPage";

import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BackIcon, CancelIcon, ValidatedIcon } from "../../../Assets/icons";

import "./UserOrderDetailsPage.css";
import KeruxDataTable from "../../base/KeruxDataTable";
import { KeruxStepper } from "../../base/stepper/KeruxStepper";
import { OrderInfo } from "../../../types";

import { useTranslation } from 'react-i18next';
import { t } from "i18next";

export const Page = styled.main`
  padding: 0;
`;
export const OrderId = styled.h3`
  margin-top: 40px;
`;

export const DetailTable = styled.table`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border-spacing: 15px;
  border-collapse: collapse;
  border: 2px solid ${(props) => props.theme.black};
  text-transform: capitalize;

  & > thead,
  tfoot {
    background-color: ${(props) => props.theme.black};
    color: ${(props) => props.theme.darckYellow};
  }

  & > tfoot td h4 {
    margin: 10px 0;
    text-align: center;
  }

  & > tfoot td h4 span {
    margin-right: 5px;
    color: #f3f3f3;
  }

  & > thead th {
    padding: 10px 5px;
    font-family: "Lato", sans-serif;
    font-size: 19px;
  }

  & > tbody tr {
    border-bottom: 1px solid;
  }

  & > tbody tr td {
    padding: 10px;
    text-align: left;
  }

  @media screen and (max-width: 400px) {
    border-spacing: 0;
    width: 100%;
    & > thead th {
      font-size: 16px;
    }

    & > tbody {
      font-size: 12px;
    }
  }
`;

export const ClientInfo = styled.div`
  & > h3 {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 440px) {
    & > h3 {
      font-size: 20px;
      align-self: center;
    }

    margin: 0 auto;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
`;

const SplitSection = styled.section`
  display: flex;
  max-width: 900px;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  gap: 20px;
  flex-wrap: wrap;

  & > * {
    width: 100%;
    flex: 1 1 400px;
  }
`;

const ColorBadg = styled.span`
  color: red;
  font-weight: 900;
  font-size: 12px;
`;

const ImageDesc = styled.img`
  width: 100px;
  height: 70px;
  transition: all 0.5s ease;
  border-radius: 15px;

  &:hover {
    transform: scale(1.1);
  }
`;

export function DeliveryCoordinatesSections(props: { order: OrderInfo }) {
  const { t } = useTranslation();
  return (
    <div className="sectionContainer">
      <div className="sectionTitle">{t("Cordonnées De Livraison")}</div>
      <div className="cordonneesData">
        <div className="coordonneesColumn">
          <div className="coordonneesRow firstRow">
            <div className="coordonneesTitle">{t("Destinataire")}</div>
            <div className="data">{props.order.client[0]?.name}</div>
          </div>
          <div className="coordonneesRow">
            <div className="coordonneesTitle">{t("Téléphone")}</div>
            <div className="blue data">{props.order.phone_number}</div>
          </div>
          <div className="coordonneesRow">
            <div className="coordonneesTitle">{t("Quartier")}</div>
            <div className="data">{t(props.order.quartier)}</div>
          </div>
          <div className="coordonneesRow">
            <div className="coordonneesTitle">{t("Commentaire")}</div>
            <div className="blue data">{t(props.order.comment || "")}</div>
          </div>
          <div className="coordonneesRow lastRow">
            <div className="coordonneesTitle">{t("Adresse")}</div>
            <div className="data">{t(props.order.address)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function OrderStatesStepper(props: { order: OrderInfo }) {
  if (!props.order?.states) return null;

  let activeState = 0;
  for (let i = 0; i < props.order.states.length; i++) {
    if (props.order.states[i].confirmed) activeState = i + 1;
  }

  const steps = (props.order.states || []).map((state, i) => ({
    title: state.name,
    text: state.confirmed
      ? new Date(state.date).toLocaleString()
      : i == activeState
      ? t("En cours...")
      : "",
    completed: state.confirmed,
  }));

  return <KeruxStepper activeIndex={activeState} steps={steps} />;
}

export function OrderDetailsTable(props: { order: OrderInfo }) {
  return (
    <KeruxDataTable
      center
      headRow={<></>}
      footerRow={
        <div className="tableFoot">
          <div className="displayRow bonusPoints">
            <div>{t("Points Bonus")}</div>
            {props.order.use_score ? (
              <ValidatedIcon height={10} fill="#F5C41D" />
            ) : (
              <CancelIcon height={10} fill="#F5C41D" />
            )}
          </div>
          <div className="displayRow">
            <div>{t("Code Réduction")}</div>
            {props.order.promo_code_pourcentage ? (
              <ValidatedIcon height={10} fill="#F5C41D" />
            ) : (
              <CancelIcon height={10} fill="#F5C41D" />
            )}
          </div>
          <div className="displayRow ms-4">
            <div>{t("Prix de livraison :")} {props.order.liv_price}</div>
          </div>
          <div className="totalPrice">
            {t("TOTAL À PAYER")} {props.order.total_price_with_liv} {t("DA")}
          </div>
        </div>
      }
      fields={[
        t("Image Produit"),
        t("Nom Produit"),
        t("Portion"),
        t("Prix"),
        t("Quantité"),
        t("Total"),
      ]}
    >
      {props.order.description?.map((productInf) => {
        const priceWithReduction =
          productInf?.product?.price * (1 - productInf?.product?.sold / 100);
        return (
          <tr key={productInf._id}>
            <td>
              <img
                className="tableImg my-0"
                src={productInf?.product?.img}
                alt={productInf?.product?.name}
              />
            </td>
            <td>{t(productInf?.product?.name || "Nom du produit non disponible")}</td>

            <td className="tableHighlightBlue">{t("1 XL")}</td>
            <td>
              {productInf.product.sold ? (
                <>
                  <s className="text-danger">
                    {productInf?.product.price + " "} {t("DA")}
                  </s>{" "}
                  <br />
                </>
              ) : null}
              {priceWithReduction + " "} {t("DA")}
            </td>
            <td className="tableHighlightBlue">{productInf?.quantity}</td>
            <td>
              {priceWithReduction * productInf?.quantity + " "}
             {t(" DA")}
            </td>
          </tr>
        );
      })}
    </KeruxDataTable>
  );
}

export default function OrderDetails() {
  let { thisOrder, isLoading } = useUserOrderDetailsPage();

  return isLoading || !thisOrder ? null : (
    <Container>
      {/*<GoBackLink to="/myAccount/myOrders">Retour</GoBackLink>
      <OrderId>
        Nº ID:<span></span>
      </OrderId>*/}

      {/*<pre>*/}
      {/*  {JSON.stringify(thisOrder, null, "\t")}*/}
      {/*</pre>*/}
      <div className="backTitleContainer">
        <Link to="/myAccount/myOrders" className="text-decoration-none">
          <Button size="sm" className="px-2 me-1">
            <BackIcon fill="white" width={14} />
          </Button>
          {t("Retour")}
        </Link>
        <div className="backTitle fw-semi-bold">{t("Détails de la commande")}</div>
        <div className="empty" />
      </div>

      <div className="pageTitle mt-3">
        {t("N° De Commande")} <span className="orderId">{thisOrder?.orderID}</span>
      </div>

      <Container className="my-4 mx-auto" style={{ maxWidth: 700 }}>
        <OrderStatesStepper order={thisOrder} />
      </Container>

      {/*<UserOrderStateChart
        states={thisOrder?.states?.filter((state) => state?.confirmed === true)}
      />*/}
      {/*<h3>Détails de l'envoi</h3>
      <SplitSection>
        <ClientInfo>
          <p>
            <b>Destinataire :</b>
            {thisOrder?.client[0]?.name}
          </p>
          <p>
            <b>Adresse :</b>
            {thisOrder?.address + " | " + thisOrder?.quartier}
          </p>
          <p>
            <b>Téléphone :</b>
            {thisOrder?.phone_number}
          </p>
          <p>
            <b>Type de la commande :</b>
            {thisOrder?.order_type + " "}
            <ColorBadg>{thisOrder?.liv_price + " DA" || ""}</ColorBadg>
          </p>

          <p>
            <b>Use Score : </b>
            {thisOrder?.use_score + " Points" || ""}
          </p>

          <p>
            <b>Promo code used : </b>
            {thisOrder?.promo_code_pourcentage + "%" || ""}
          </p>

          <p>
            <b>Comment : </b>
            {thisOrder?.comment || ""}
          </p>
        </ClientInfo>
      </SplitSection>*/}

      <DeliveryCoordinatesSections order={thisOrder} />

      <Container className="p-0">
        <div className="sectionTitle">{t("Détails De Commande")}</div>
        <OrderDetailsTable order={thisOrder} />
      </Container>

      {/*<div className="sectionContainer">*/}
      {/*  <div className="sectionTitle">Détails De Commande</div>*/}
      {/*  <div className="flex-nowrap overflow-auto kerux-scrollbar row">*/}
      {/*    <div className="">*/}
      {/*      <table cellSpacing="0">*/}
      {/*        <thead>*/}
      {/*        <th className="firstTh">*/}
      {/*          Image Produit*/}
      {/*        </th>*/}
      {/*        <th>Nom Produit</th>*/}
      {/*        <th>Portion</th>*/}
      {/*        <th>Prix</th>*/}
      {/*        <th>Unités</th>*/}
      {/*        <th className="lastTh">*/}
      {/*          Total*/}
      {/*        </th>*/}
      {/*        </thead>*/}
      {/*        <tbody>*/}
      {/*        {thisOrder?.description?.map((productInf) => (*/}
      {/*          <tr key={productInf._id}>*/}
      {/*            <td>*/}
      {/*              <img*/}
      {/*                className="tableImg"*/}
      {/*                src={productInf?.product?.img}*/}
      {/*                alt={productInf?.product?.name}*/}
      {/*              />*/}
      {/*            </td>*/}
      {/*            <td>{productInf?.product?.name}</td>*/}
      {/*            <td className="tableHighlightBlue">1 XL</td>*/}
      {/*            <td>*/}
      {/*              {productInf?.product?.price + " "}*/}
      {/*              DA*/}
      {/*            </td>*/}
      {/*            <td className="tableHighlightBlue">*/}
      {/*              {productInf?.quantity}*/}
      {/*            </td>*/}
      {/*            <td>*/}
      {/*              {productInf?.product?.price * productInf?.quantity + " "}*/}
      {/*              DA*/}
      {/*            </td>*/}
      {/*          </tr>*/}
      {/*        ))}*/}
      {/*        </tbody>*/}
      {/*      </table>*/}
      {/*      <div className="tableRow">*/}
      {/*        <div className="displayRow bonusPoints">*/}
      {/*          <div>Points Bonus</div>*/}
      {/*          <ValidatedIcon height={10} fill="#F5C41D"/>*/}
      {/*        </div>*/}
      {/*        <div className="displayRow">*/}
      {/*          <div>Code Réduction</div>*/}
      {/*          <ValidatedIcon height={10} fill="#F5C41D"/>*/}
      {/*        </div>*/}
      {/*        <div className="totalPrice">TOTAL À PAYER 3000 DA</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Container>
  );
}
