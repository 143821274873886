import React, {useState} from "react";
import type {BannerImage} from "../../../types";
import DashboardSectionContainer from "./common/DashboardSectionContainer";
import {Button, Col, Container, Row} from "react-bootstrap";
import KeruxDataTable from "../../base/KeruxDataTable";
import styled from "styled-components";
import {useForm} from "react-hook-form";
import {DELETE, POST} from "../../../utils/https";
import {LoaderSpinner} from "../LoaderSpinner";
import FormTextInput from "../../base/form/FormTextInput";
import {useStorage} from "../../../context/useStorage";

import { useTranslation } from 'react-i18next';

const bannersURL = "/api/banners"


const StyledBannerImageContainer = styled(Container)`
  width: 100%;
  //height: 214.46px;
  border: 2px dashed #0369b2;
  text-align: center;
  border-radius: 8px;
  margin: 19.2px 0;
  display: inline-block;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

function useNewBannerForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    mode: "onBlur",
  });

  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState<boolean>();

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", data.title);
    formData.append("order_img", data.order);
    formData.append("img", selectedImage)

    POST("/api/banners", formData, "file")
      .then(({response, json}) => {

        if (json && json.successful) {
          window.location.reload();
        } else {
          alert("Une erreur est survenue!");
          setIsLoading(false);
        }
      }).catch(e => {
      console.error(e);
      alert("Une erreur est survenue!")
      setIsLoading(false);
    });
  }

  
  return {
    selectedImage, setSelectedImage,
    register,
    reset,
    onSubmit: handleSubmit(onSubmit),
    errors,
    isLoading,
    setIsLoading
  }
}

async function DeleteBanner(banner: BannerImage) {
  const { t } = useTranslation();
  if (window.confirm(`${t("Etes vous sur de vouloir supprimer la bannière ")}` + banner.name + " ?")) {
    await DELETE("/api/banners/" + banner._id);
    window.location.reload();
  }
}

function AddBannerForm(props) {
  const { t } = useTranslation();
  const {selectedImage, reset, setSelectedImage, isLoading, register, onSubmit, errors} = useNewBannerForm();

  // This function will be triggered when the file field change
  function imageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  }

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage(undefined);
    reset()
  };


  return <Container className="text-start mb-3">
    <form id="bannerFactory" name="bannerFactory" onSubmit={onSubmit}>
      <h4>{t("Insérer une image")}</h4>

      <Row>
        <Col>
          <label htmlFor="image-upload" className=""/>
          <input
            id="image-upload"
            accept="image/*"
            type="file"
            name="img"
            ref={register({
              required: true,
            })}
            onChange={imageChange}
            required
          />

          {selectedImage && (
            <>
              <StyledBannerImageContainer>
                <img src={URL.createObjectURL(selectedImage)}
                     alt="produit"
                     id="selected_img"
                />
              </StyledBannerImageContainer>
              <Button variant="danger" onClick={removeSelectedImage}>
                {t("Retirer l'image")}
              </Button>
            </>
          )}
        </Col>
        <Col>

          <Container className="text-start">
            <FormTextInput label={t("Titre de la bannière")} required type="text" name="title" refProp={register({
              required: true,
            })}/>
            <FormTextInput label={t("Ordre de la bannière")} required type="number" name="order" refProp={register({
              required: true,
            })}/>

            <div className="text-end mt-3">
              {
                isLoading ? <LoaderSpinner small/> :
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    {t("Ajouter l'image")}
                  </Button>
              }
              {
                ///TODO: Show errors
              }
            </div>
          </Container>
        </Col>
      </Row>
    </form>
  </Container>
}


export function DashboardBanners(props) {
  const {banners} = useStorage();
  const { t } = useTranslation();
  return <DashboardSectionContainer title={t("Gestion des Bannières")}>
    <Container className="text-start">
      <AddBannerForm/>
      <KeruxDataTable fields={["#", t("Nom"), t("Bannière"), t("Action")]} hover={false} center={false}
                      headRow={<></>} rowClickable={false}>
        {
          banners?.map((banner, i) => <tr key={banner.img_id}>
            <td>{banner.order_img + 1}</td>
            <td>{banner.name}</td>
            <td width="60%"><img className="w-75" src={banner.img} alt="Banner Image"/></td>
            <td><Button color="danger" onClick={() => DeleteBanner(banner)}>{t("Supprimer")}</Button></td>
          </tr>)
        }
      </KeruxDataTable>

    </Container>
  </DashboardSectionContainer>
}