import React, {useState} from "react";
import {Button, Col, Container, Form, InputGroup, Modal, Row, Table} from "react-bootstrap";
import styled from "styled-components";
import {FilterIcon, RefreshIcon, SearchIcon} from "../../Assets/icons";


import { useTranslation } from 'react-i18next';

const StyledTable = styled(Table)<{ hover?: boolean }>`
  vertical-align: middle;
  box-shadow: 0 4px 16px #b1b1b152;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;

  thead, tbody {
    tr {
      border-color: white;

      th, td {
        padding-top: var(--bs-gutter-y, 0.75rem);
        padding-bottom: var(--bs-gutter-y, 0.75rem);

      }

      th:first-child, td:first-child {
        padding-left: var(--bs-gutter-x, 0.75rem);
        //border-left: 1px solid #b1b1b152;
      }

      th:last-child, td:last-child {
        padding-right: var(--bs-gutter-x, 0.75rem);
        //border-right: 2px solid #b1b1b152;
      }
    }

  }

  tbody {
    tr {
      &:hover {
        border-color: ${({hover}) => hover ? "var(--bs-table-hover-bg)" : "initial"};

        th, td {
          border-color: ${({hover}) => hover ? "var(--bs-table-hover-bg)" : "initial"};
          --bs-table-accent-bg: ${({hover}) => hover ? "var(--bs-table-hover-bg)" : "initial"};
        }
      }
    }
  }
`;

const StyledTableBody = styled.tbody<{ clickable?: boolean }>`
  background: white;
  font-weight: 500;
  border-top: 0 !important;
  font-size: 1.2em;
  vertical-align: inherit;

  tr {
    cursor: ${({clickable}) => clickable ? "pointer" : "inherit"};
  }
`


function FiltersModal({show, handleClose}: { show: boolean, handleClose: () => void }) {
  const { t } = useTranslation();
  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{t("Modal heading")}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{t("Woohoo, you're reading this text in a modal!")}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
      {t("Close")}        
      </Button>
      <Button variant="primary" onClick={handleClose}>
        {t("Save Changes")}
      </Button>
    </Modal.Footer>
  </Modal>
}

function FiltersButton() {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const { t } = useTranslation();
  return <>
    <Button onClick={handleShow} variant="outline-light" className="border-0" size="sm">
       {t("Filter")}
      <FilterIcon fill="white" height={10}/>
    </Button>
    <FiltersModal handleClose={handleClose} show={showModal}/>
  </>
}

function DefaultControlsHeadRow(props: {
  searchPlaceholder?: string;
  searchValue?: string;
  setSearchValue?: (string) => void;
  onSearch?: (string) => void;
  onRefresh?: () => void;
}) {

  const { t } = useTranslation();
  return <Row className="align-items-center pt-4">
    <Col md="3" xs="6">
      <Form className="d-flex p-2 px-4" onSubmit={(e) => {
        e.preventDefault();
        if (props.onSearch) props.onSearch(props.searchValue)
      }}>
        <InputGroup className="me-2">
          <Form.Control
            size="sm"
            type="search"
            placeholder={props.searchPlaceholder ?? t("Rechercher")}
            aria-label={props.searchPlaceholder ?? t("Rechercher")}
            aria-describedby="search"
            value={props.searchValue}
            onChange={props.setSearchValue ? (e) => props.setSearchValue(e.target.value) : null}
          />
          <InputGroup.Text id="search">
            <SearchIcon onClick={props.onSearch ? () => props.onSearch(props.searchValue) : null}
                        className="fill-primary" height={16}/>
          </InputGroup.Text>
        </InputGroup>
      </Form>
    </Col>
    <Col>
    </Col>
    <Col/>
    <Col>
      <Container className="d-flex align-items-center justify-content-end">
        <RefreshIcon onClick={props.onRefresh} height={16} className="fill-highlight pointer"/>

        {/*<FiltersButton/>*/}
      </Container>
    </Col>
  </Row>
}

export default function KeruxDataTable(props: {
  headRow?: React.ReactNode;
  searchPlaceholder?: string;
  searchValue?: string;
  setSearchValue?: (string) => void;
  onSearch?: (string) => void;
  onRefresh?: () => void;
  footerRow?: React.ReactNode;
  fields: string[];
  center?: boolean;
  hover?: boolean;
  rowClickable?: boolean;
  children?: React.ReactNode;
}) {
  return (<StyledTable hover={props.hover ?? true} size="sm" responsive="sm"
                       className={"kerux-scrollbar " + (props.center ? "text-center" : "")}>
    <thead className="bg-primary text-white border-0">
    {
      props.headRow ? <tr>
          <th className="border-0" colSpan={props.fields.length}>
            {props.headRow}
          </th>
        </tr> :
        <tr>
          <th className="border-0" colSpan={props.fields.length}>
            <DefaultControlsHeadRow searchPlaceholder={props.searchPlaceholder} searchValue={props.searchValue}
                                    setSearchValue={props.setSearchValue} onSearch={props.onSearch}
                                    onRefresh={props.onRefresh}/>
          </th>
        </tr>
    }
    <tr>
      {props.fields.map((h, i) => <th key={h + i}>{h}</th>)}
    </tr>
    </thead>
    <StyledTableBody clickable={props.rowClickable}>
      {props.children}
    </StyledTableBody>
    {
      props.footerRow ? <tfoot className="border-0">
      <tr>
        <td className="border-0 p-0" colSpan={props.fields.length}>
          {props.footerRow}
        </td>
      </tr>
      </tfoot> : null
    }
  </StyledTable>)
}