import {useEffect, useState} from "react";
import {useCartStorage} from "../context/cart_context/useCartStorage";
import {ProductInfo} from "../types";
import {toastSuccess} from "../ui/utils/toasts";

import {useTranslation} from 'react-i18next'
export default function useAddToCartButton(item) {
  const {cartProducts, addToCart,} = useCartStorage();

  const [isAdded, setIsAdded] = useState(false);
  const { t } = useTranslation();
  let products = cartProducts.map((product) => product._id);

  useEffect(() => {
    products.indexOf(item._id) !== -1 ? setIsAdded(true) : setIsAdded(false);
  }, [products, item]);

  const handlerAddToCartAndAddToTotalCost = (product: ProductInfo, reduction) => {
    addToCart(product, reduction);
    //toastSuccess(`${t(product.name)} ${t("ajouté")}`);
    toastSuccess(`${t("ajouté")} ${t(product.name)}`);

    return;
  };

  return {handlerAddToCartAndAddToTotalCost, isAdded};
}
