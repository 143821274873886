import { Col, Container, Row } from "react-bootstrap";
import { LoaderSpinner } from "../../LoaderSpinner";

import { useTranslation } from 'react-i18next';

export default function DashboardSectionContainer(props) {
  
  const { t } = useTranslation();
  return (
    <Container
      style={props.style}
      className={"mt-5 " + (props.isLoading ? "text-center " : "")}
    >
      {props.isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <Row>
            <Col>{props.backLink}</Col>
            <Col>
              <h2 className="text-center text-primary text-nowrap fw-semi-bold">
                {t(props.title)}
              </h2>
            </Col>
            <Col></Col>
          </Row>

          {props.children}
        </>
      )}
    </Container>
  );
}
